*{
  font-family: 'Aboreto', cursive;
}
.warning, .form-text{
  font-size: .8rem;
}

h1{
  font-size: 4rem;
  text-align: center;
}
h1::first-letter {
  color: #f65868;
  text-align: center;
}
p{
  color: #f65868;
}
.warning{
  color:#000;
}